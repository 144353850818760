import Tree1 from "public/hero-illustration/trees/tree-1.svg";

import GeorgiaHouse from "public/hero-illustration/house/georgia-house.svg";
import HouseWithBushes from "public/hero-illustration/house/house-with-bushes.svg";

import GrassTop from "public/hero-illustration/grass/grass-top.svg";
import GrassCenter from "public/hero-illustration/grass/grass-center.svg";
import GrassBottom from "public/hero-illustration/grass/grass-bottom.svg";

import Sky from "public/hero-illustration/sky/sky.svg";
import SkyBlob1 from "public/hero-illustration/sky/sky-blob-1.svg";
import SkyBlob2 from "public/hero-illustration/sky/sky-blob-2.svg";

import Cloud1 from "public/hero-illustration/clouds/cloud-1.svg";
import Cloud2 from "public/hero-illustration/clouds/cloud-2.svg";
import Cloud3 from "public/hero-illustration/clouds/cloud-3.svg";
import Cloud4 from "public/hero-illustration/clouds/cloud-4.svg";
import Cloud5 from "public/hero-illustration/clouds/cloud-5.svg";

import GeorgiaSkyline from "public/hero-illustration/state-items/georgia-skyline.svg";
import NewYorkSkyline from "public/hero-illustration/state-items/newyork-skyline.svg";
import { StatesWithStatePage } from "utils/types";

const StateSpecificIllustration = (target: StatesWithStatePage) => {
	switch (target) {
		case "GA":
			return (
				<>
					<Cloud3 id="hero-illustration-cloud-3-goergia" />
					<GeorgiaSkyline id="hero-illustration-georgia-skyline" />
					<GeorgiaHouse id="hero-illustration-house-with-bushes" />
				</>
			);
			break;
		case "NY":
			return (
				<>
					{/* <Cloud3 id="hero-illustration-cloud-3-goergia" /> */}
					<NewYorkSkyline id="hero-illustration-newyork-skyline" />
					<HouseWithBushes id="hero-illustration-house-with-bushes" />
				</>
			);
			break;
		default:
			return null;
	}
};

export const HeroIllustrationStateSpecific = ({
	target,
}: {
	target: StatesWithStatePage;
}) => {
	return (
		<div
			id="landing-hero-illustration"
			className={"state-specific-illustration-" + target.toLowerCase()}>
			<Sky id="hero-illustration-sky" />
			<SkyBlob1 id="hero-illustration-sky-blob-1" />
			<SkyBlob2 id="hero-illustration-sky-blob-2" />

			<Cloud1 id="hero-illustration-cloud-1" />

			<Cloud2 id="hero-illustration-cloud-2" />

			<Cloud3 id="hero-illustration-cloud-3" />

			<Cloud4 id="hero-illustration-cloud-4" />

			<Cloud5 id="hero-illustration-cloud-5" />

			<GrassTop id="hero-illustration-grass-top" />
			<GrassCenter id="hero-illustration-grass-center" />

			<GrassBottom id="hero-illustration-grass-bottom" />

			<Tree1 id="hero-illustration-tree-1" />

			{StateSpecificIllustration(target)}
		</div>
	);
};
