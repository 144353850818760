import { HeroAdressInput } from "./HeroAdressInput";
import { HeroValueProps } from "./HeroValueProps";
import { StatesWithStatePage } from "utils/types";
import { HeroIllustrationStateSpecific } from "./StatePages/HeroIllustrationStateSpecific";

export const StateSpecificHeroSection = ({
	title,
	target,
}: {
	title: string;
	target: StatesWithStatePage;
}) => {
	return (
		<div id="landing-hero-section">
			<HeroIllustrationStateSpecific target={target} />
			<HeroAdressInput title={title} />
			<HeroValueProps />
		</div>
	);
};
