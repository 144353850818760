import Router from "next/router";
import { StatesWithStatePage, StatesWithStateSpecificPage } from "utils/types";
import constants from "utils/constants";
import { useEventTracker } from "utils/useEventTracker";
import statePages from "config/state-pages.json";
import { useEffect, useMemo, useState } from "react";
import { useAuthContext } from "context/AuthContext";
import AnnouncementBanner from "elements/AnnouncementBanner";
import RealtorReferralPartnerBanner from "./RealtorReferralPartnerBanner";
import Script from "next/script";
import Head from "next/head";
import { HeroSection } from "./HeroSection";
import { MarqueeFeaturedSection } from "./MarqueeFeaturedSection";
import { StatSection } from "./StatSection";
import { BigNumberCounterSection } from "./BigNumberCounterSection";
import {
	MoreWaysToSaveSection,
	MoreWaysToSaveService,
} from "./MoreWaysToSaveSection";
import { TestimonialsSection } from "./TestimonialsSection";
import { SignUpStepsSection } from "./SignUpStepsSection";
import { FAQSection } from "./FAQSection";
import Header from "layout/Header";
import Footer from "layout/Footer";
import PropertyTax101Section from "./PropertyTax101Section";

import { ExcellenceAward } from "./StatePages/ExcellenceAward";
import { StateSpecificHeroSection } from "./StateSpecificHeroSection";

import dynamic from "next/dynamic";
import type { MoreWaysToSaveSectionProps } from "./MoreWaysToSaveSection";
import type { TestimonialsSectionProps } from "./TestimonialsSection";
import type { CountiesServedAndDatesProps } from "./StatePages/CountiesServedAndDates";
import type { SignUpStepsSectionProps } from "./SignUpStepsSection";
import type { PropertyTax101SectionProps } from "./PropertyTax101Section";
import type { FAQSectionProps } from "./FAQSection";

const LazyMoreWaysToSave = dynamic<MoreWaysToSaveSectionProps>(
	() =>
		import("./MoreWaysToSaveSection").then(
			module => module.MoreWaysToSaveSection
		),
	{
		loading: () => null,
		ssr: false,
	}
);

const LazyTestimonialsSection = dynamic<TestimonialsSectionProps>(
	() =>
		import("./TestimonialsSection").then(module => module.TestimonialsSection),
	{
		loading: () => null,
		ssr: false,
	}
);

const LazyCountiesServedAndDates = dynamic<CountiesServedAndDatesProps>(
	() =>
		import("./StatePages/CountiesServedAndDates").then(
			module => module.CountiesServedAndDates
		),
	{
		loading: () => null,
		ssr: false,
	}
);

const LazySignUpStepsSection = dynamic<SignUpStepsSectionProps>(
	() =>
		import("./SignUpStepsSection").then(module => module.SignUpStepsSection),
	{
		loading: () => null,
		ssr: false,
	}
);

const LazyPropertyTax101Section = dynamic<PropertyTax101SectionProps>(
	() => import("./PropertyTax101Section").then(module => module.default),
	{
		loading: () => null,
		ssr: false,
	}
);

const LazyFAQSection = dynamic<FAQSectionProps>(
	() => import("./FAQSection").then(module => module.FAQSection),
	{
		loading: () => null,
		ssr: false,
	}
);

const PAGE_TITLE = "Ownwell: Lower Your Property Taxes";
const PAGE_DESCRIPTION =
	"Save money on your property taxes. With no upfront commitment, our local experts manage your appeal from start to finish. Pay just 25% of what you save. Start your appeal today.";
const STATES_WITH_COUNTIES_BANNER = ["GA", "FL"];

const HAS_BEEN_ON_LANDING_PAGE = "shown_landing_page";

const STATES_WITH_NO_PROPERTY_TAX_PAGE = ["NY", "IL"];

const StateSpecificLandingPage = ({
	target,
	dynamicTitle,
}: {
	target: StatesWithStatePage;
	dynamicTitle: string | null;
}) => {
	const { isAuthenticated } = useAuthContext();
	const trackEvent = useEventTracker();

	useEffect(() => {
		trackEvent({
			eventName: constants.EVENTS.HOME_PAGE_VISIT,
			data: {
				Variant: target ? target : "false",
			},
		});
	}, []);

	const hasBeenOnLandingPage = useMemo(() => {
		try {
			if (typeof window === "undefined" || window.sessionStorage === undefined)
				return false;
			return !!window.sessionStorage.getItem(HAS_BEEN_ON_LANDING_PAGE);
		} catch {
			console.error("Cannot access sessionStorage");
		}
	}, []);

	useEffect(() => {
		try {
			if (
				isAuthenticated &&
				!hasBeenOnLandingPage &&
				window.sessionStorage !== undefined
			) {
				window.sessionStorage.setItem(HAS_BEEN_ON_LANDING_PAGE, "1");
				Router.push("/account/properties");
			}
		} catch {
			console.error("Cannot access sessionStorage");
		}
	}, [hasBeenOnLandingPage, isAuthenticated]);

	const showAnnouncementBanner = false;

	const servicesByState = {
		GA: ["appeal", "exemption", "georgia-freeze", "georgia-appeal-rule"],
		NY: [
			"appeal-no-click",
			"new-york-exemption",
			"new-york-nassau",
			"new-york-scar",
		],
	};

	return (
		<>
			<style jsx global>
				{`
					body {
						background-color: #edf6ff;
					}
				`}
			</style>
			<Script
				id="ze-snippet"
				strategy="lazyOnload"
				src="https://static.zdassets.com/ekr/snippet.js?key=a6eaeaac-9943-44de-8edd-efde086ea77d"
				onLoad={() => {
					if (typeof window === "undefined") return;
					//@ts-ignore
					window.zESettings = {
						webWidget: {
							helpCenter: {
								originalArticleButton: false,
							},
						},
					};
				}}
			/>
			<div
				id="site-layout"
				className={[showAnnouncementBanner ? "fancy-w-banner" : "fancy"].join(
					" "
				)}>
				<Header>{showAnnouncementBanner && <AnnouncementBanner />}</Header>
				<main id="site-body">
					<div id="landing-fancy" className="landing-state-page">
						<Head>
							<title>{PAGE_TITLE}</title>
							<meta property="title" content={PAGE_TITLE} key="title" />
							<meta property="og:title" content={PAGE_TITLE} key="og:title" />
							<meta
								name="description"
								content={PAGE_DESCRIPTION}
								key="description"
							/>
							<meta
								property="og:description"
								content={PAGE_DESCRIPTION}
								key="og:description"
							/>
						</Head>
						<RealtorReferralPartnerBanner />
						<StateSpecificHeroSection
							target={target}
							title={
								dynamicTitle
									? dynamicTitle
									: `Save money on your ${
											target ? statePages[target].name + " " : ""
										}property taxes`
							}
						/>
						<div className="cloud-gradient-sections">
							<MarqueeFeaturedSection />
							<StatSection />
						</div>
						<BigNumberCounterSection />
						<div className="sky-light-gradient-sections">
							<LazyMoreWaysToSave
								target={target}
								title={
									<>
										Property Taxes in{" "}
										<span className="kelly-gradient">
											{(target && statePages[target].name) ?? "your state"}
										</span>
									</>
								}
								services={
									servicesByState[
										target as StatesWithStateSpecificPage
									] as MoreWaysToSaveService[]
								}
							/>
							<LazyTestimonialsSection target={target} />
							<LazyCountiesServedAndDates
								target={target as StatesWithStateSpecificPage}
							/>
							<LazySignUpStepsSection />
							{target && !STATES_WITH_NO_PROPERTY_TAX_PAGE.includes(target) && (
								<LazyPropertyTax101Section target={target} />
							)}
						</div>
						<ExcellenceAward target={target as StatesWithStateSpecificPage} />
						<LazyFAQSection target={target} />
					</div>
				</main>
				<Footer />
			</div>
		</>
	);
};

export default StateSpecificLandingPage;
