import Grid from "elements/Grid";
import Image from "next/image";
import { StatesWithStateSpecificPage } from "utils/types";

import ChristianE from "public/landing/state-pages/excellence/christian-e.jpeg";
import JordanR from "public/landing/state-pages/excellence/jordan-r.jpeg";

const EXCELLENCE_AWARD_BY_STATE = {
	GA: {
		title: "2023 Excellence Award - Southeast",
		name: "Christian E.",
		image: ChristianE,
		role: "Property Tax Expert",
		location: "Atlanta, GA",
		text: "Christian is an experienced property tax consultant based in Atlanta, GA. Christian has helped both commercial and residential property owners save millions of dollars each year. Christian previously consulted at Marvin F. Poer, studied at the Institute for Professionals in Taxation (IPT), and earned a bachelor's degree from the University of Mississippi where he majored in Managerial Finance.",
	},
	NY: {
		title: "2023 Excellence Award",
		name: "Jordan R.",
		image: JordanR,
		role: "Property Tax Expert",
		location: "Long Island, NY",
		text: "Jordan is a Certified General Appraiser in New York State, with a focus on property tax consultation in Long Island, New York. With a background as a commercial real estate appraiser spanning more than a decade, Jordan has successfully transitioned to specializing in tax grievances, leveraging his extensive experience to benefit property owners.",
	},
};

export const ExcellenceAward = ({
	target,
}: {
	target?: StatesWithStateSpecificPage;
}) => {
	if (!target) return null;
	return (
		<div id="landing-excellence-award">
			<Grid>
				<h3 className="landing-excellence-award-title mb-4">
					{EXCELLENCE_AWARD_BY_STATE[target].title}
				</h3>
				<div className="landing-excellence-award-image">
					<Image
						src={EXCELLENCE_AWARD_BY_STATE[target].image}
						alt="Excellence Award"
					/>
				</div>
				<div className="landing-excellence-award-content">
					<h4 className="mb-2">
						{EXCELLENCE_AWARD_BY_STATE[target].name}
						<br />
						<span className="bold">
							{EXCELLENCE_AWARD_BY_STATE[target].role}
						</span>
					</h4>
					<p className="semibold mb-2 xl">
						Location: {EXCELLENCE_AWARD_BY_STATE[target].location}
					</p>
					<p className="landing-excellence-award-text lg">
						{EXCELLENCE_AWARD_BY_STATE[target].text}
					</p>
				</div>
			</Grid>
		</div>
	);
};
