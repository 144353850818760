import LandingFancy from "./LandingFancy";
import { StatesWithStatePage } from "utils/types";
import StateSpecificLandingPage from "./StateSpecificLandingPage";

interface StateSpecificLandingPageProps {
	target: StatesWithStatePage;
	dynamicTitle: string | null;
}

const LandingPageSetup = (props: {
	target?: StatesWithStatePage;
	dynamicTitle: string | null;
}) => {
	return (
		<>
			{props.target === "GA" || props.target === "NY" ? (
				<StateSpecificLandingPage
					{...(props as StateSpecificLandingPageProps)}
				/>
			) : (
				<LandingFancy {...props} />
			)}
		</>
	);
};

export default LandingPageSetup;
